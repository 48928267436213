.inform-list {
  margin-bottom: 20px;
}
.inform-item {
  min-height: 20px;
  border-bottom: solid 1px #CCCCCC;
  margin-top: 8px;
  padding-bottom: 8px;
}

.inform-item a {
  color: #1d2124;
}

.list-footer-2 {
  padding-top: 20px;
}

.hot-new-item {
  margin-bottom: 10px;
}

.hot-new-item a {
  color: #1d2124;
}

.hot-new-image {
  width: 150px;
  float: left;
}

.hot-new-image img {
  width: 140px;
}
.header-hot-notification {
  background-color: #1c40a0;
  padding: 5px 5px 5px 10px;
  margin-bottom: 10px;
  margin-top: 20px;
}
.header-hot-notification a {
  color: #FFFFFF;
}