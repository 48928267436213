.left-area-title {
    text-align: center;
    font-weight: bold;
    height: 30px;
    margin-top: 10px;
    border-bottom: solid 1px #CCCCCC;
    text-transform: uppercase;
}
.left-area-item {
    height: 32px;
    background-image: url("../../assets/icon/arrow-right-white.png");
    background-repeat: no-repeat;
    background-position: right;
    border: solid 1px #eeeeee;
    margin: 5px 5px 5px 5px;
    padding: 5px 5px 5px 5px;
}

.left-area-item a {
    color: #FFFFFF;
}

.left-area-item a:hover {
    color: #FFFFFF;
}

.item-color-1 {
    background-color: #1c40a0;
}
.item-color-2 {
    background-color: #61AC69;
}
.item-color-3 {
    background-color: #FFAF18;
}
.item-color-4 {
    background-color: #FF7918;
}
.item-color-5 {
    background-color: #FF4F18;
}
.item-color-6 {
    background-color: #DB353C;
}

.general-planning-map-view-container {
    .left-custom-navbar {
        transition-duration: 0.5s;
        top: 0px;

        .custom-button-left-menu {
            top: 10px;
            left: 330px;
            background-color: #1c40a0;
            padding: 6px 12px;

            img {
                width: 18px;
            }
        }

        .custom-sub-map-tools-panel {
            z-index: 50;
        }
    }

    .custom-position-filter-infomation-popup {
        top: 20px;
        right: 120px;
    }

    .map-tools-container {
        .map-tool-panel-container {
            top: 50px;
            right: 10px;
        }
    }
}