.comment-title {
  font-weight: bold;
  margin-bottom: 10px;
}
.comment-content {
  min-height: 20px;
  margin-bottom: 10px;
}
.comment-button-box {
  min-height: 20px;
  text-align: right;
}

.comment-button-box button {
  background-color: #012169;
  color: #FFFFFF;
  border: solid 1px #CCCCCC;
  text-align: center;
  padding: 5px 20px 5px 20px;
  border-radius: 3px;
}

.comment-list {
  max-height: 500px;
  overflow-y: auto;
}

.h-30 {
  height: 30px;
}

.comment-entry {
  min-height: 20px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.comment-entry-count {
  min-height: 20px;
  margin-bottom: 10px;

}
.comment-entry-count .time-answer {
  font-size: 12px;
  font-style: italic;
}


.comment-replay {
  margin-left: 20px;
  border-left: solid 3px #DDDDDD;
  padding-left: 20px;
}