.cb {clear: both;}
.home-news {
  margin-bottom: 60px;
}
.home-news .news_title {
  font-weight: bold;
  color:#0D0B52;
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.home-news .see-more {
  margin-top: 30px;
}

.home-news .new-item {
  margin-bottom: 20px;
}

.home-news .new-item .new-image {
  width: 100%;
}

.home-news .new-item .new-image img {
  width: 100%;
  max-width: 100%;
  max-height: 180px;
  height: 180px;
  margin-bottom: 20px;
}

.home-news .new-item .new-info a {
  color: #1b1e21;
}

#home-hot-news {
  .news-image {
    min-height: 300px;
    border-radius: 3px !important;
  }

  .news-image img {
    width: 100%;
    height: auto;
    border-radius: 3px !important;
  }

  .news-title {
    min-height: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
    font-size: 16px;
  }

  .news-title a {
    font-weight: bold;
    font-size: 16px;
    color: #3a3a3a;
  }

  .news-date {
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .news-date span {
    position: relative;
    top:2px;
    left:10px;
    color: #999999;
  }
}

.home-other-news {
  .news-item {
    min-height: 30px;
    margin-bottom: 30px;
  }
  .news-image {
    min-height: 100px;
    border-radius: 3px !important;
    width: 25%;
    float: left;
  }

  .news-info {
    width: 75%;
    float: left;
  }

  .news-info .news-date {
    margin-top: 5px;
  }

  .news-info .news-date span {
    position: relative;
    top:2px;
    left:10px;
    color: #999999;
  }

  .news-image img {
    width: 90%;
    height: auto;
    border-radius: 3px !important;
  }

  .news-title {
    min-height: 20px;
    font-weight: normal !important;
    text-align: justify;
  }

  .news-title a {
    font-weight: normal !important;
    font-size: 16px;
    color: #3a3a3a;
  }
}