.cb {clear: both !important}
.trial-box-container {
  position: absolute;
  width: 100%;
}
#trial-box {
  position: relative;
  top: -160px;
  color: #FFFFFF;
  text-align: center;
  cursor: pointer;
  border: solid 1px #FFFFFF;
  padding: 20px;
  width: 88%;
  margin: auto;
  border-radius: 5px;
  background-color: #999999;
}

#trial-box-1 {
  text-align: center;
  text-decoration: none !important;
  cursor: none;
}