@media (max-width: 767px) {
  .main-nav {
    height: 44px;
  }
  .site-logo {
    width: 100px;
    margin: auto;
    position: inherit;
  }
  .site-logo img {
    width: 40px;
    height: 40px;
    z-index: 1000;
  }
  #site-menu {
    position: absolute;
    left: 10px;
  }
  #top-split-line {
    display: none;
  }

  #entry_do_thi_pc {
    display: none;
  }
  #entry_do_thi_mobile {
    display: block;
  }

  #base-map-list {
    width: 150px;
  }

  #home-slider {
    display: none;
  }

  .navbar-toggler {
    margin-left: -15px;
    margin-top: 3px;
  }
  #navbarNav {
    position: absolute;
    z-index: 99999;
    background-color: #1c40a0;
    border: solid 1px #CCCCCC;
    margin-left: -15px;
    top: 62px;
    width: 99%;
    padding: 10px;
  }

  .dropdown-item {
    white-space:inherit !important;
  }

  .dropdown-menu {
    max-width: 300px !important;
  }

  #responsive-navbar-nav {
    position: relative;
    background-color: #5e8eff;
    left: -25px;
    top: 12px;
    padding-left: 10px;
  }

  .header-container .navbar ul.navbar-nav li.nav-item.padding-left-menu {
    padding-left: 0px !important;
  }

  .main-nav ul li ul.level1 {
    width: 100% !important;
  }

  .nav-item {
    border-bottom: solid 1px #FFFFFF;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .level1_mobile {
    max-width: 100% !important;
    width: 100% !important;

  }

  .header-container .navbar ul.navbar-nav li.nav-item.dropdown .dropdown-menu a {
    background-color: #038FF4;
  }

  #home-banner {

  }
  #mobile-account-link {
    display: block;
  }
  .container {
    max-width: 100% !important;
  }

  .home-page {
    position: relative;
  }


  .home-page .home-search {
    top: 20px !important;
    position: inherit;
  }

  .home-page .home-search-box {
    position: relative;
    top: -200px;
  }

  .home-page .home-search-border {
    box-shadow:none !important;
    border: none !important;
    padding: 5px 10px 5px 10px !important;
    background-color: transparent !important;
    margin-top: 240px !important;
    width: 100% !important;
  }

  .planning-announcement .header-doing .input-search {
    width: 150px !important;
  }

  .home-page .home-search .home-title {
    position: relative;
    top: 180px !important;
    font-size: 16px !important;
  }
  .home-page .home-search .home-title2 {
    position: relative;
    top: 190px !important;
    font-size: 16px !important;
  }

  .home-page .home-search-container {
    width: 100% !important;
    background-color: #FFFFFF;
    padding: 5px;
    min-height: 115px;
  }

  .entry-box {
    top: 300px !important;
  }

  .entry-container {
    width: 100%;
  }

  #nav-split-line {
    display: none;
  }

  #nav-languages img {float: right !important;}

  .entry-item {
    width: 45% !important;
    margin-bottom: 10px;
    margin-left: 3% !important;
  }

  .home-page .search-box-place .search-place-input {
    width: 70% !important;
    border: solid 1px #CCCCCC;
  }

  .home-page .search-option-box {
    position: relative !important;
    background-color: transparent !important;
    z-index: 1000;
    width: 100% !important;
    border-right: none !important;
  }

  .home-page .search-box-planning .search-planning-input {
    width: 70% !important;
    border: solid 1px #CCCCCC;
    z-index: 99999;
  }

  .home-page .search-box-planning .search-planning-button {
    width: 30% !important;
    border: solid 1px #CCCCCC;
    border-radius: 0px !important;
  }

  .navbar-toggler {
    border: solid 1px #FFFFFF !important;
  }
  .navbar-light .navbar-toggler-icon {
    background-image: url("./bars-solid.svg") !important;
  }
  .home-page .search-box-place .search-place-button {
    width: 30% !important;
    border: solid 1px #CCCCCC;
  }

  .home-page .search-box-planning {
    width: 100% !important;
    margin-bottom: 100px !important;
  }

  .home-page .search-box-place {
    width: 100% !important;
  }



  .home-page .home-search .home-search-action-box {
    width: 90% !important;
  }


  #map-tool-panel-right {
    top: 70px;
    right: 100px;
    z-index: 10;
  }

  #entry-panel-right {
    top: 10px;
    right: 10px;
    z-index: 90;
  }

  #map-tool-list {
    z-index: 9999;
    top: 110px;
    width: 318px !important;
  }

  #right-panel-tool-filter-box {
    top: 200px;
    right: -10px;
    width: 320px;
    border-radius: 1px;
  }
  #map-tool-panel-right-search {
    display: none;
  }
  #map-tool-panel-right-base-map {

  }

  #map-tool-panel-right-base-map .select-base-map {
    left: 10px;
  }

  #planning-infomation-small-popup {
    right: -182px;
    top: 50px;
  }
  #map-tool-panel-right-search-result {
    position: relative;
    top: 60px !important;
    left: -40px !important;
    z-index: 1 !important;
  }

  #map-tool-panel-right-search-result form {
    width: 336px !important;
    z-index: 1 !important;
  }

  #map-tool-panel-right-search-result .map__autocomplete-container {
    width: 336px !important;
    left: 0px !important;
    top: 42px !important;
  }

  #map-tool-panel-right-search-result-action {
    width: 336px !important;
    z-index: 1 !important;
  }
  #map-tool-panel-right-search-result-action img {
    display: inline-block !important;
    z-index: 1 !important;
  }
  .header-container .navbar .navbar-brand {
    margin-left: 300px;
  }

  .home-news .new-item .new-image img {
    max-height: 200px !important;
    height: 200px !important;
  }



  #footer-note-1 {
    display: none !important;
  }

  #footer-note-2 {
    display: block !important;
    margin-top: 20px;
    margin-bottom: 50px;
  }

  #footer-title-name {
    margin-top: 30px;
  }

  #footer-title-box {
    margin-bottom: 30px;
  }

  .planning-map-view-container .planning-top-box {
    padding-top: 3px;
    padding-right: 20px;
  }

  .planning-map-view-container .planning-top-name {
    position: fixed;
    bottom: 80px;
    right: 50px;
    z-index: 1000;
    display: none !important;
  }

  #map-detail-related-button {
    bottom: 50px !important;
    left: 0px !important;
  }

  .footer-container {
    margin-top: 20px;
  }

  .first-news-image img {
    height: auto !important;
  }

  .home-other-news .news-image {
    width: 50% !important;
  }

  .home-other-news .news-info {
    width: 45% !important;
  }

  .home-other-news .news-item {
    margin-bottom: 20px !important;
  }
}