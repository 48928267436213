.page-top-area {
  background-color: #E5E7F3;
  padding: 10px;
}
.top-right-box {

}

.top-right-box a {
  color: #1b1e21 !important;
  font-size: 14px;
}

.top-right-box span {
  color: #999999;
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.site-flag {
  position: absolute;
  top: 50px;
  right: 18px;
}

.flag-vn {
  margin-left: 10px;
  margin-right: 10px;
}
