.mobile-background {
  background: url(./slider/slider-01.jpg) no-repeat center bottom;
  height: 880px;
}

@media only screen and (min-width: 961px) and (max-width: 1024px) {
  .home-search-border {
    width: 80% !important;
  }
  .search-option-box {
    width: 34% !important;
  }
  .search-box-planning {
    width: 66% !important;
  }
  .search-box-place {
    width: 66% !important;
  }
  .search-place-input {
    width: 76% !important;
  }
  .search-planning-input {
    width: 76% !important;
  }
  .search-place-button {
    width: 24% !important;
  }
  .search-planning-button {
    width: 24% !important;
  }


  #site-menu {
    top: 10px;
    right: 0px;
  }
  .navbar-nav {
    font-size: 14px !important;
  }

  .nav-item {
    font-size: 14px !important;
  }

}


@media only screen and (min-width: 1025px) and (max-width: 1400px) {
  .home-page {
    .home-map {
      .map-content {
        right: 215px;
      }
    }
  }
}