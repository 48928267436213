.province-planning {
  .province-search-box {
    min-height: 40px;
    padding: 10px;
  }
  .province-item {
    height: 32px;
    width: 45%;
    float: left;
    background-image: url("../../../assets/icon/arrow-right.png");
    background-repeat: no-repeat;
    background-position: right;
    margin: 5px 2% 5px 2%;
    padding: 5px 5px 5px 5px;
  }
  .province-item a {
    color: #4e555b;
  }
  .province-item a:hover {
    color: #4e555b;
  }
}