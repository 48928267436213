.link-history {
  margin-top: 20px;
  margin-bottom: 20px;
}

.link-history div {
  vertical-align: middle;
}

.project-desc {
  min-height: 30px;
  margin-bottom: 10px;
}

.priority-header {
    font-weight: bold;
    border-bottom: solid 2px #DB353A;
    margin-bottom: 30px;
}

.priority-refresh-button {
  min-height: 20px;
  padding: 4px ;
  background-color: #DDDDDD;
  text-align: center;
  border: solid 1px #CCCCCC;
  border-radius: 3px;
  margin-bottom: 15px;
  cursor: pointer;
  color: #1d5d90;
  font-size: 14px;
}

.Collapsible {
  min-height: 20px;
  padding-top: 5px;
  background-color: #F5F5F5;
  text-align: left;
  border: solid 1px #CCCCCC;
  border-radius: 2px;
  margin-bottom: 5px;
  cursor: pointer;
}

.Collapsible .is-closed {
  background-image: url("../../assets/images/arrow-up-3.png");
  background-repeat: no-repeat;
  background-position:  right;
}

.Collapsible .is-open {
  background-image: url("../../assets/images/arrow-down-3.png");
  background-repeat: no-repeat;
  background-position:  right;
}

.Collapsible__trigger {
  background-color: #F5F5F5;
  margin-left: 10px;
  height: 20px;
  margin-bottom: 10px;
  width: 90%;
  display: inline-block;
}
.Collapsible__contentOuter {
  background-color: #FFFFFF;
}

.collapse-item {
  margin-left: 10px !important;
  margin-top: 10px !important;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
}

.collapse-item select {
  width: 100%;
  height: 30px;
  border: solid 1px #DDDDDD;
}


.priority-search-button {
  background-color: #011B69 !important;
  color: #FFFFFF !important;
  cursor: pointer;
}

.priority-search-result {
  min-height: 20px;
  padding: 10px;
  border: solid 1px #CCCCCC;
  border-radius: 2px;
  margin-top: 10px;
}

.project-title {
  min-height: 20px;
  margin-bottom: 10px;
}

.project-title  a {
  font-weight: bold;
  color: #011B69;
}

.project-action-list {
  min-height: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.project-action-list button {
  margin-right: 10px;
}

.project-more-info {
  min-height: 20px;
  color: #333333;
}

.project-more-info span {
  font-weight: bold;
}

.priority-paging-box {
  min-height: 40px;
  margin-bottom: 20px;
  margin-top: 20px;
}