footer {
    background: #026BD6;
    color: #fff;
}

footer .name {
    padding: 25px 0;
}

footer .name:after {
    clear: both;
    display: block;
    content: "";
}

footer .name img {
    float: left;
    margin-right: 15px;
    height: 89px;
}

footer .name h3 {
    font-weight: 700;
    font-size: 13px;
    line-height: 24px;
    text-transform: uppercase;
    margin: 12px 0 0;
}

footer .detail {
    padding-top: 33px;
}

footer .detail p {
    margin-bottom: 2px;
    font-size: 12px;
    line-height: 26px;
}

footer .detail p svg {
    width: 14px;
    margin-right: 6px;
    vertical-align: middle;
    color: yellow;
}

footer .copyright {
    border-top: 1px solid #fff;
    padding: 12px 0;
    font-size: 12px;
    line-height: 16px;
}

.related-website {
    margin-top: 40px;
}
.related-website label {
    font-size: 18px;
}