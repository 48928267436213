.document-container {
  padding-left: 10px;
  margin: auto;
}
.document-menu {
  margin-top: 20px;
  min-height: 500px;
  margin-bottom: 20px;

}
.document-search-box {
  margin-top: 20px;
}
.doc-table {
  margin-top: 20px;
  border-left: solid 1px #CCCCCC;
}

.doc-table-header {
  background-color: #D9EDF7;
}

.document-action-list {
  width: 100% !important;
  margin-top: 10px;
}
.document-action-list a {
  font-weight: normal;
}
.document-action-list a:hover {
  text-decoration: none;
}

.document-number {
  width: 120px !important;
}

.document-publish-date {
  width: 120px !important;
  font-size: 13px;
  color: #4e555b;
}

.doc-number {
  min-height: 30px;
}

.doc-publish-date {
  font-size: 13px;
  color: #4e555b;
}

.document-search-box {

}

#publish-date, #docNumber {
  max-width: 100% !important;
}

.close-preview-file {
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding-top: 4px;
  background-color: #1d2124;
  position: absolute;
  top:1px;
  left:100px;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
}

.document-search-box #document_number {
  width: 97%; padding-left: 3%;
  height: 30px;
  padding-top: 2px;
  padding-bottom: 2px;
  border: solid 1px #CCCCCC;
}

.document-search-box #description {
  width: 97%; padding-left: 3%;
  height: 30px;
  padding-top: 2px;
  padding-bottom: 2px;
  border: solid 1px #3a3a3a;
  border-radius: 2px ;
}

.document-search-box .react-date-picker {
  width: 97%; padding-left: 3%;
  height: 30px;
  padding-top: 2px;
  padding-bottom: 2px;
  border: solid 1px #CCCCCC;
}

.document-search-box .react-date-picker .react-date-picker__wrapper {
  border: none;
}