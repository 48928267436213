.special-planning {
  .special-search-box {
    min-height: 40px;
    padding: 10px;
  }
  .special-left-area-title {
    text-align: center;
    font-weight: bold;
    height: 60px;
    margin-top: 10px;
    margin-bottom: 20px;
    border-bottom: solid 1px #CCCCCC;
    text-transform: uppercase;
  }

  .special-item {
    height: 32px;
    width: 95%;
    float: left;
    background-image: url("../../../assets/icon/arrow-right.png");
    background-repeat: no-repeat;
    background-position: right;
    margin: 5px 2% 5px 2%;
    padding: 5px 5px 5px 5px;
    background-color: #E5E7F2;
  }
  .special-item a {
    color: #4e555b;
  }
  .special-item a:hover {
    color: #4e555b;
  }
}