.guide-use-map-container {
    overflow: hidden;

    #accordion-guide-use-map {
        padding-top: 15px;

        .list-group {
            padding: 0 15px;

            .list-group-item {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                color: #1c40a0;
                pointer-events: none;
            }

            div[class$='collapsed'] {
                cursor: pointer;
                pointer-events: unset;
                color: #333333;
            }

            .active {
                background-color: #1c40a0;
                color: white;
                outline: none;
            }
        }

        .content-collapse {
            margin-left: -10px;
            padding-right: 10px;
            padding-bottom: 16px;

            .collapse {
                height: 92%;
                overflow: auto;
            }
        }
    }
}