.link-history {
  margin-top: 20px;
  margin-bottom: 20px;
}

.link-history div {
  vertical-align: middle;
}

.priority-header {
    font-weight: bold;
    border-bottom: solid 2px #DB353A;
    margin-bottom: 30px;
}

.download-assest-button {
  width: 90% !important;
  margin: auto;
  background-color: #011B69;
  text-align: center !important;
  padding: 5px 10px 5px 10px ;
  border-radius: 3px;
}

.download-assest-button a {
  color: #FFFFFF;
}
.download-assest-button a:hover {
  color: #FFFFFF;
  text-decoration: none;
}

.priority-refresh-button {
  min-height: 20px;
  padding: 4px ;
  background-color: #DDDDDD;
  text-align: center;
  border: solid 1px #CCCCCC;
  border-radius: 3px;
  margin-bottom: 15px;
  cursor: pointer;
  color: #1d5d90;
  font-size: 14px;
}

.Collapsible {
  min-height: 20px;
  padding-top: 5px;
  background-color: #F5F5F5;
  text-align: left;
  border: solid 1px #CCCCCC;
  border-radius: 2px;
  margin-bottom: 5px;
  cursor: pointer;
}

.Collapsible .is-closed {
  background-image: url("../../assets/images/arrow-up-3.png");
  background-repeat: no-repeat;
  background-position:  right;
}

.Collapsible .is-open {
  background-image: url("../../assets/images/arrow-down-3.png");
  background-repeat: no-repeat;
  background-position:  right;
}

.Collapsible__trigger {
  background-color: #F5F5F5;
  margin-left: 10px;
  height: 20px;
  margin-bottom: 10px;
  width: 90%;
  display: inline-block;
}
.Collapsible__contentOuter {
  background-color: #FFFFFF;
}

.collapse-item {
  margin-left: 10px !important;
  margin-top: 10px !important;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
}

.collapse-item select {
  width: 100%;
  height: 30px;
  border: solid 1px #DDDDDD;
}

.assest-header {
  font-weight: bold;
  margin-bottom: 20px;
}

.assest-table {
  min-height: 30px;
  margin-bottom: 30px;
}

.assest-table th {
  border: solid 1px #CCCCCC; padding: 5px 5px 5px 5px;
}
.assest-table tr {
  border: solid 1px #CCCCCC; padding: 5px 5px 5px 5px;
}

.assest-table tr td{
  border: solid 1px #CCCCCC; padding: 5px 5px 5px 5px;
}

.related-info-box {
  min-height: 20px;
  border: solid 1px #CCCCCC;
  padding: 5px 5px 5px 5px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  margin-bottom: 20px;
}

.related-info-box .planning-title {
  min-height: 20px;
  margin-bottom: 10px;
  border-bottom: dotted 1px #CCCCCC;
  padding: 5px 0px 5px 0px;
}

.related-info-box .planning-title  a {
  color: #011B69;
  cursor: pointer;
}


.priority-search-button {
  background-color: #011B69 !important;
  color: #FFFFFF !important;
  cursor: pointer;
  text-align: center !important;
  width: 100px;
  margin: auto !important;
  padding-right: 0px !important;
}

.priority-search-result {
  min-height: 25px;
  padding: 10px 10px 10px 10px;
  border: solid 1px #CCCCCC;
  border-radius: 2px;
  margin-top: 10px;
}
.priority-search-result .planning-title {
  min-height: 25px;
  margin-bottom: 10px;
}

.planning-more-info {
  min-height: 20px;
  padding-top:5px;
  padding-bottom:5px;
}

.planning-more-info .file-format {
  background-color: #3a3a3a;
  color: #FFFFFF;
  padding: 3px 5px 3px 5px;
  border-radius: 2px;
  cursor: pointer;

}

.planning-more-info span {
  font-weight: bold;
}

.planning-document-attach {

}

.planning-document-attach span {
  font-weight: bold;
}

.planning-document-attach ul {
  margin-top: 10px;
}

.planning-document-attach ul li {
  list-style-type: none;
}

.planning-document-attach ul li a {
  color: #1b1e21;
}


.priority-search-result .planning-title a {
  color: #011B69;
  cursor: pointer;
  font-weight: bold;
}



.planning-desc {
  min-height: 20px;
  margin-bottom: 10px;
}


.planning-result-more-info {
  min-height: 20px;
  color: #333333;
  background-color: #F5F5F5;
  padding: 5px 10px 5px 10px;
  margin-bottom: 20px;
  border: solid 1px #CCCCCC;
}

.planning-result-more-info .more-title {
  font-weight: bold;
}

.priority-paging-box {
  min-height: 40px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.header-widget {
    height: 2.5rem;
    background: linear-gradient(90deg, #1c40a0, #1c40a0);
    padding: 0.375rem;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    .title {
        padding-top: 0.2rem;
        color: #ffffff;
    }
}

.new-image {
    text-align: center;
    width: 50%;
    padding: 5px;
}