.intro-page {

}

.intro-title {
  font-size: 16px;
  font-weight: bold;
  margin-top: 30px;
}

.intro-content {

}

.intro-line {
    margin-top: 20px;
}

.intro-line ul li {
  list-style-type: none;
  margin-top: 10px;
}

.intro-sign {
  margin-top: 10px;
  margin-bottom: 40px;
}