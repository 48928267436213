.notification {
  background: #EEEEEE;
  padding: 30px 0 0;
}

.notification .new-nav .nav-action {cursor: pointer;}
.new-image {text-align: center}

.notification h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  color: #3342B9;
  border-left: 3px solid #FFAF18;
  padding-left: 5px;
  margin-bottom: 20px;
}

.notification .new-nav {
  text-align: right;
}

.notification .new-nav .nav-action {
  display: inline-block;
  width: 22px;
  line-height: 22px;
  height: 22px;
  border: 1px solid #333333;
  text-align: center;
  margin-left: 5px;
}

.notification .new-nav .nav-action svg {
  vertical-align: middle;
  height: 20px;
}

.notification .hot-new {
  margin-bottom: 30px;
}

.notification .hot-new .new-image {
  text-align: center;
}

.notification .hot-new .new-image img {
  margin-top: 20px;
  max-width: 100%;
}

.notification .hot-new .new-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #333333;
  display: block;
  margin: 10px 0 5px;
}

.notification .hot-new .new-date {
  font-size: 16px;
  line-height: 19px;
  color: #A4A4A4;
  margin-bottom: 10px;
}

.notification .hot-new .new-desc {
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 16px;
  text-align: justify;
}

.notification .hot-new .view-detail {
  color: #3342B9;
  font-size: 16px;
  line-height: 19px;
}

.notification .hot-new .view-detail svg {
  vertical-align: unset;
  margin-left: 5px;
}

.notification .hot-new .view-detail:hover {
  text-decoration: none;
}

.notification .other-notification {
  margin-bottom: 30px;
}

.notification .other-notification .new-item {
  margin-bottom: 20px;
}

.notification .other-notification .new-item:after {
  content: "";
  display: block;
  clear: both;
}

.notification .other-notification .new-item:last-child {
  margin-bottom: 0;
}

.notification .other-notification .new-item .new-image {
  width: 200px;
  height: 110px;
  overflow: hidden;
  float: left;
}

.notification .other-notification .new-item .new-image img {
  max-width: 100%;
}

.notification .other-notification .new-item .new-info {
  margin-left: 220px;
}

.notification .other-notification .new-item .new-info .new-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333;
  display: block;
  margin-bottom: 6px;
}

.notification .other-notification .new-item .new-info .new-date {
  font-size: 16px;
  line-height: 19px;
  color: #A4A4A4;
  margin-bottom: 28px;
}

.notification .other-notification .new-item .new-info .view-detail {
  color: #3342B9;
  font-size: 16px;
  line-height: 19px;
}

.notification .other-notification .new-item .new-info .view-detail svg {
  vertical-align: unset;
  margin-left: 5px;
}

.notification .other-notification .new-item .new-info .view-detail:hover {
  text-decoration: none;
}

@media (max-width: 575px) {
  .notification .other-notification .new-item .new-image {
    width: 150px;
    height: 100px;
  }
  .notification .other-notification .new-item .new-info {
    margin-left: 160px;
  }
  .notification .other-notification .new-item .new-info .new-date {
    margin-bottom: 20px;
  }
}
.notification-title-box {
  min-height: 20px;
}
.detail-title {margin-bottom: 10px;}
.detail-title .notification-title { font-weight: bold; font-size: 16px; color: #1d2124;}
.notification .hot-new .new-date {margin-top: 10px;}
.notification .other-notification .new-item .new-info .view-detail {color: #1075BD;}

.header-other-notification {
  background-color: #1c40a0;
  padding: 5px 5px 5px 10px;
  margin-bottom: 10px;
}
.header-other-notification a {
  color: #FFFFFF;
}